/* Add this CSS to your project to style the legend as needed */
.gradient-legend {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    font-size: 12px;
    line-height: 1.5;
    z-index: 1;
  }
  
  .legend-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .legend-bar {
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, #d9f0d1, #00441a);
    margin-bottom: 5px;
  }
  
  .legend-scale {
    display: flex;
    justify-content: space-between;
  }  
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Add fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation for plant cards when they first appear */
.plant-card {
  animation: fadeIn 0.6s ease-in-out;
}

/* Hover effect for plant cards */
.plant-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
