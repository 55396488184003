.App {
  background-color: white;
  min-height: 100vh;
  /* Remove or adjust the following rule to fix layout constraints */
  text-align: left; /* Set to left or remove it */
  padding: 0;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
