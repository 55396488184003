.flip-card {
    background-color: transparent;
    width: 100%;
    height: 150px; /* Adjusted height for more space */
    perspective: 1000px;
    margin-bottom: 15px; /* Increased margin for better spacing */
    border-radius: 15px; /* Increased roundness for modern look */
    transition: box-shadow 0.3s ease; /* Smooth shadow transition */
}
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s ease-in-out; /* Smooth flip transition */
    transform-style: preserve-3d;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Soft shadow */
    border-radius: 15px; /* Match radius with the outer card */
}
  
.flip-card:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3); /* Add hover shadow for depth */
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}
  
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 15px; /* Same rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px; /* Increased padding for text readability */
}
  
.flip-card-front {
    background-color: #4CAF50; /* Darker green for contrast */
    color: white;
}
  
.flip-card-back {
    background-color: #2E7D32; /* More contrast for the back */
    color: white;
    transform: rotateY(180deg);
}
